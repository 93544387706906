<template>
  <v-app>
    <div>
      <b-form @submit.stop.prevent="formOnsubmit">
        <MainForm
          :form="form"
          :error="error"
          :purpose="purpose"
          :mainSentenceShow="false"
          citizenTitleButton="Pilih Penduduk"
        >
          <template v-slot:main>
            <label for="">Usaha Mulai Sejak:</label>
            <div class="row mb-2">
              <div class="col pt-3">
                <v-dialog
                  ref="dialog"
                  v-model="modal2"
                  :return-value.sync="form.start_date"
                  persistent
                  width="350px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <b-input-group>
                      <b-input-group-prepend>
                        <button
                          class="btn btn-secondary"
                          type="button"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <i class="fas fa-calendar"></i>
                        </button>
                      </b-input-group-prepend>
                      <b-form-input
                        id="input-trigger-modal-patient"
                        v-model="form.start_date"
                        placeholder="Tanggal (YYYY-MM-DD)"
                        readonly
                      >
                      </b-form-input>
                    </b-input-group>
                  </template>

                  <v-date-picker
                    v-if="modal2"
                    v-model="form.start_date"
                    locale="id"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal2 = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(form.start_date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </div>
            </div>

            <b-form-group
              id="input-group-name"
              label="Nama Usaha:"
              label-for="input-name"
            >
              <b-form-input
                id="input-name"
                v-model="form.name"
                placeholder="Nama Usaha"
              ></b-form-input>
              <small class="text-danger">{{ error.name }}</small>
            </b-form-group>
            <!-- <b-form-group
              id="input-group-purpose"
              label="Tujuan Pembuatan Surat:"
              label-for="input-purpose"
            >
              <b-form-input
                id="input-purpose"
                v-model="form.purpose"
                placeholder="Tujuan Pembuatan Surat"
              ></b-form-input>
              <small class="text-danger">{{ error.purpose }}</small>
            </b-form-group> -->
            <b-form-group id="input-group-address">
              <label for="input-address">Alamat Usaha: </label>
              <b-form-textarea
                id="input-address"
                v-model="form.address"
                placeholder="Alamat Usaha"
                rows="4"
                max-rows="8"
              ></b-form-textarea>
              <small class="text-danger">{{ error.address }}</small>
            </b-form-group>
          </template>
        </MainForm>
        <!-- Submit & Cancel button -->
        <b-button type="submit" variant="primary">Simpan</b-button>
        <b-button
          type="button"
          class="ml-2"
          variant="default"
          @click="$router.push('/bussiness-certificate')"
        >
          Batal
        </b-button>
      </b-form>
    </div>
  </v-app>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import MainForm from "@/view/components/letters/Form.vue";

export default {
  props: {
    form: Object,
    route: String,
    purpose: {
      type: String,
      default: "add",
    },
  },

  components: {
    MainForm,
  },

  data() {
    return {
      // Error
      error: {
        id_card_number: "",
        gender: "",
        birt_place: "",
        birt_date: "",
        title: "",
        number: "",
        hamlet_id: "",
        purpose: "",
        //
        citizen_id: "",
        name: "",
        address: "",
        purpose: "",
      },
      //   formResource: {
      //     resource_name: "",
      //     resource_id_card_number: "",
      //     resource_birt_place: "",
      //     resource_birt_date: "",
      //     resource_profession: "",
      //     resource_address: "",
      //     resource_birt_place_date: "",
      //   },
      modal2: false,
    };
  },

  methods: {
    async formOnsubmit() {
      // Make Request
      let response = await module.submit(this.form, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/bussiness-certificate");
      }
    },
  },
  mounted() {},
};
</script>

<style>
</style>